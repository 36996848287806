import Layout from '../components/Layout'
import React, { Component } from 'react'
import Banner from '../components/Banner'
import Carousel from '../components/carousel/Carousel'
import Releases from '../components/releases/Releases'
import Video from '../components/video/Video'
import Spotify from '../components/Spotify'
import { contentfulClient } from '../services/contentful'
import quotes from '../assets/quotes';

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      releases: [],
      video: {
        url: 'https://www.youtube.com/watch?v=TGjKIAJws24' //default video
      }
    }
  }

  componentDidMount() {
    contentfulClient
      .getEntries({
        content_type: 'releases',
        order: '-sys.createdAt',
        limit: 4,
      })
      .then(data => {
        const releases = data.items.map(release => ({
          ...release.fields,
          id: release.sys.id,
        }))

        this.setState({ releases })
      })
    contentfulClient
      .getEntries({
        content_type: 'video',
        limit: 1,
        order: '-sys.createdAt',
        select: 'sys.id,fields',
      })
      .then(data => {
        const videos = data.items.map(video => ({
          url: video.fields.url,
          id: video.sys.id,
        }))

        this.setState({video: videos[0]})
      })
  }

  render() {
    let { releases, video, bio } = this.state

    return (
      <Layout>
        <div>
          <Banner {...releases[0] || {}} bio={bio} />

          <div id="main">
            <section id="releases" className="tiles">
              <Releases releases={releases} />
            </section>

              <section id="testimonials">
                <Carousel quotes={quotes} />
              </section>

            <section>
              <Video video={video} />
            </section>

            <section>
              <Spotify />
            </section>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Index
