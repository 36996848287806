import React, { Component } from "react";
import styled from "styled-components";
import Observer from "@researchgate/react-intersection-observer";
import Youtube from "react-youtube";

export const Container = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
`;

const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  transition: all 1s ease;
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const VideoBottomFrame = styled.div`
  position: absolute;
  bottom: 0;
  top: 90vh;
  left: 0;
  right: 0;
  background-color: rgba(2, 57, 74, 1);
`;

const YoutubeIcon = styled.i`
  z-index: 9;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 8rem;
  transition: all 0.5s ease;
  transform: translate(-50%, -50%)
    scale(${props => (props.isMouseHovering ? "1" : "0.5")});
  opacity: ${props => (props.isMouseHovering ? 1 : 0)};
`;

const YoutubePlayer = styled(Youtube)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Link = styled.a`
  &:hover {
    color: rgba(241, 250, 238, 1) !important;
  }
`;

class Video extends Component {
  constructor(props) {
    super(props);

    this.state = {
      videoPlayer: null,
      isVideoPlayerReady: false
    };

    this.handleOnVideoReady = this.handleOnVideoReady.bind(this);
    this.handleIntersection = this.handleIntersection.bind(this);
    this.handleMouseHover = this.handleMouseHover.bind(this);
  }

  handleIntersection(event) {
    if (this.state.isVideoPlayerReady) {
      if (event.isIntersecting) {
        this.state.videoPlayer.playVideo();
      }
    }
  }

  handleOnVideoReady(event) {
    this.setState({
      videoPlayer: event.target,
      isVideoPlayerReady: true
    });
  }

  handleMouseHover() {
    this.setState({ isMouseHovering: !this.state.isMouseHovering });
  }

  render() {
    const observerOptions = {
      onChange: this.handleIntersection,
      rootMargin: "25%"
    };

    const videoOptions = {
      playerVars: {
        autoplay: 0,
        controls: 0,
        mute: 1,
        rel: 0
      }
    };

    return (
      <Container>
        <Observer {...observerOptions}>
          <Link
            href={this.props.video.url}
            target="_blank"
            rel="noreferrer noopener"
          >
            <VideoOverlay
              onMouseOver={this.handleMouseHover}
              onMouseLeave={this.handleMouseHover}
            />
            <YoutubeIcon
              className="fa fa-youtube fa-5x"
              isMouseHovering={this.state.isMouseHovering}
            />
            <YoutubePlayer
              videoId={this.props.video.url.split("=")[1]}
              opts={videoOptions}
              onReady={this.handleOnVideoReady}
            />
            {/*<VideoBottomFrame/>*/}
          </Link>
        </Observer>
      </Container>
    );
  }
}

export default Video;
