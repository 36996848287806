import React, { Component } from "react";
import Cloud from "./Cloud";
import posed from "react-pose";
import { Center, Title } from "../styledComponents";

const CloudAnimated = posed.div({
  pulseBig: {
    scale: 1
  },
  pulseSmall: {
    scale: 0.7
  }
});

class CloudSpinner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pulsing: true
    };
  }

  componentDidMount() {
    this.intervalId = setInterval(() => {
      this.setState({ pulsing: !this.state.pulsing });
    }, 500);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    return (
      <>
        <CloudAnimated pose={this.state.pulsing ? "pulseBig" : "pulseSmall"}>
          <Cloud style={{ transform: "scale(0.2)" }} />
        </CloudAnimated>
        <Center>
          <Title>Loading...</Title>
        </Center>
      </>
    );
  }
}

export default CloudSpinner;
