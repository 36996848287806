import styled from "styled-components";
import React from 'react'

export const Title = styled.h1`
  font-size: 1.2rem;
  &::after {
    background-color: transparent !important;
  }
`;

export const Center = styled.div`
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CenterAbsolute = styled.div`
  position: absolute;
  top: 50% ;
  left: 50%;
  transform: translate(-50%, -50%);
`;