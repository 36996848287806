import React from 'react';

const CloudlandLogo = () => {
  return (
    <svg version="1.1" id="Layer_1"
         x="0px" y="0px"
         width="200px" height="40px" viewBox="0 0 1238 266">
      <g>
        <path fill="#FFFFFF" d="M319.119,206.581h35.92c-1.069,15.555-6.548,27.621-16.437,36.201c-9.89,8.579-23.279,12.868-40.17,12.868
          c-18.923,0-33.81-5.545-44.66-16.637c-10.852-11.093-16.276-26.285-16.276-45.584c0-19.242,5.372-34.423,16.116-45.541
          c10.744-11.117,25.39-16.678,43.938-16.678c17.104,0,30.561,4.129,40.37,12.389c9.809,8.258,15.301,20.059,16.478,35.398h-36.321
          c-0.535-6.04-2.486-10.598-5.854-13.671c-3.367-3.073-8.099-4.61-14.192-4.61c-7.43,0-13.002,2.715-16.717,8.139
          c-3.716,5.427-5.572,13.617-5.572,24.574c0,10.744,1.977,18.87,5.933,24.375c3.955,5.506,9.755,8.26,17.399,8.26
          c5.879,0,10.544-1.685,13.991-5.053C316.513,217.646,318.53,212.835,319.119,206.581z"/>
        <path fill="#FFFFFF" d="M356.216,252.363V134.259h37.926v86.755h51.556v31.351L356.216,252.363L356.216,252.363z"/>
        <path fill="#FFFFFF" d="M441.342,193.432c0-19.242,5.492-34.424,16.478-45.543c10.984-11.117,25.965-16.677,44.94-16.677
          c18.922,0,33.862,5.56,44.82,16.677c10.957,11.119,16.437,26.301,16.437,45.543c0,19.19-5.479,34.356-16.437,45.502
          s-25.898,16.717-44.82,16.717c-18.977,0-33.956-5.571-44.94-16.717C446.834,227.788,441.342,212.622,441.342,193.432z
           M479.588,193.432c0,11.172,1.87,19.377,5.612,24.614c3.741,5.239,9.594,7.858,17.56,7.858c7.91,0,13.71-2.606,17.398-7.817
          c3.688-5.212,5.532-13.431,5.532-24.655c0-11.277-1.845-19.523-5.532-24.736c-3.688-5.211-9.488-7.816-17.398-7.816
          c-7.966,0-13.817,2.62-17.56,7.857C481.458,173.975,479.588,182.206,479.588,193.432z"/>
        <path fill="#FFFFFF" d="M566.236,134.259h37.925v72.804c0,6.734,1.403,11.533,4.21,14.393c2.806,2.859,7.496,4.289,14.071,4.289
          c6.414,0,10.997-1.402,13.751-4.209c2.752-2.807,4.129-7.63,4.129-14.473v-72.804h37.925v75.771
          c0,14.807-4.771,26.111-14.313,33.916s-23.372,11.705-41.492,11.705c-18.229,0-32.152-3.914-41.773-11.746
          c-9.622-7.829-14.433-19.122-14.433-33.875V134.259z"/>
        <path fill="#FFFFFF" d="M685.598,252.363V134.259h45.863c11.652,0,20.325,0.521,26.02,1.563c5.691,1.042,10.649,2.766,14.872,5.171
          c8.18,4.812,14.325,11.573,18.441,20.286c4.115,8.713,6.174,19.431,6.174,32.15c0,13.364-2.553,24.604-7.657,33.717
          c-5.104,9.113-12.628,15.89-22.569,20.326c-3.85,1.764-8.674,3.021-14.474,3.769c-5.801,0.749-15.088,1.122-27.861,1.122H685.598z
           M723.523,222.456h10.183c9.461,0,16.021-2.11,19.684-6.334c3.661-4.222,5.493-11.786,5.493-22.69
          c0-10.637-1.872-18.174-5.613-22.611c-3.741-4.437-10.103-6.653-19.082-6.653h-10.663L723.523,222.456L723.523,222.456z"/>
        <path fill="#FFFFFF" d="M799.588,252.363V134.259h37.926v86.755h51.557v31.351L799.588,252.363L799.588,252.363z"/>
        <path fill="#FFFFFF" d="M878.139,252.363l41.533-118.104h38.406l41.533,118.104h-37.604l-4.409-15.635h-37.604l-4.57,15.635
          H878.139z M926.889,211.953h23.813l-11.787-40.893L926.889,211.953z"/>
        <path fill="#FFFFFF" d="M995.656,252.363V134.259h36.001l33.114,54.923c1.015,1.551,2.098,3.742,3.246,6.575
          c1.149,2.833,2.365,6.254,3.648,10.263c-0.32-2.084-0.563-4.383-0.723-6.896c-0.159-2.513-0.239-5.56-0.239-9.142v-55.725h36.161
          v118.104h-36.161l-33.114-54.521c-1.017-1.604-2.125-3.875-3.327-6.814c-1.203-2.938-2.42-6.308-3.647-10.104
          c0.373,2.085,0.642,4.264,0.802,6.535s0.24,5.466,0.24,9.581v55.323h-36.001V252.363z"/>
        <path fill="#FFFFFF" d="M1114.537,252.363V134.259h45.863c11.651,0,20.324,0.521,26.019,1.563
          c5.692,1.042,10.65,2.766,14.873,5.171c8.179,4.812,14.325,11.573,18.441,20.286c4.114,8.713,6.174,19.431,6.174,32.15
          c0,13.364-2.553,24.604-7.657,33.717c-5.104,9.113-12.628,15.89-22.57,20.326c-3.849,1.764-8.674,3.021-14.473,3.769
          c-5.801,0.749-15.088,1.122-27.861,1.122H1114.537z M1152.463,222.456h10.184c9.461,0,16.021-2.11,19.684-6.334
          c3.661-4.222,5.493-11.786,5.493-22.69c0-10.637-1.872-18.174-5.613-22.611c-3.742-4.437-10.103-6.653-19.082-6.653h-10.664
          L1152.463,222.456L1152.463,222.456z"/>
      </g>
            <path fill="#00A8FF" d="M419.5,77.5c-5.807-6.445-30.875-33.25-72.75-17.25c-8.5-16.5-43.861-50.504-89.5-49
        c-56.875,1.875-90,41.5-99.167,75.417c-15.458-9.792-38.458-9.167-51.333-2.566C94.378,90.443,80.688,101,75.5,123.313
        c-11.25-13.188-29.438-13-36.313-10.875c-13.511,4.176-30.438,17.188-26.813,43.938c2.369,0.033,217.906-0.031,222,0.067
        c4.149-7.896,8.412-13.828,14.063-19.006c5.325-4.877,11.964-9.148,18.313-11.688c9.946-3.975,21.176-4.787,31.667-4.833
        c9.326-0.041,18.933,0.487,27.083,2.646c8.728,2.311,15.848,6.419,21.125,11.438c-0.009-1.61,0.033-2.702,0.063-5.376
        c1.328-1.297,4.229-4,5.625-5.313c5.313-0.088,35.781-0.17,45.063-0.25c2.594,2.422,4.232,3.971,6.125,5.938
        c0.111,8.208,0,19.25,0.002,26.4c6.369-0.008,13.125-0.017,13.687-0.025c5.018-5.551,16.007-19.096,16.063-40.438
        C433.293,99.313,424.75,83.327,419.5,77.5z"/>
            <g>
        <path fill="#FFFFFF" d="M1042.554,97.763c1.548-1.892,3.285-3.298,5.212-4.218s4.084-1.38,6.476-1.38
          c2.632,0,4.823,0.529,6.578,1.586c1.755,1.058,2.924,2.55,3.509,4.476c1.668-2.098,3.474-3.633,5.417-4.605
          c1.942-0.971,4.171-1.458,6.682-1.458c1.857,0,3.509,0.271,4.953,0.813s2.632,1.346,3.562,2.412
          c0.79,0.929,1.357,2.038,1.701,3.328c0.345,1.29,0.517,3.363,0.517,6.217v16.227h-5.134v-16.433c0-2.993-0.521-5.112-1.562-6.359
          c-1.04-1.247-2.771-1.87-5.198-1.87c-3.096,0-5.614,0.946-7.559,2.837c-1.943,1.892-2.915,4.377-2.915,7.456v14.369h-4.953v-16.433
          c0-2.993-0.52-5.112-1.561-6.359s-2.773-1.87-5.198-1.87c-3.096,0-5.615,0.946-7.559,2.837c-1.943,1.892-2.916,4.377-2.916,7.456
          v14.369h-5.134V92.887h5.082V97.763z"/>
              <path fill="#FFFFFF" d="M1119.422,92.887v28.274h-5.057v-4.798c-1.565,1.892-3.303,3.298-5.211,4.218
          c-1.909,0.92-4.067,1.38-6.476,1.38c-1.841,0-3.482-0.28-4.928-0.839s-2.64-1.371-3.586-2.438
          c-0.791-0.929-1.358-2.042-1.702-3.341c-0.345-1.299-0.517-3.375-0.517-6.23V92.887h5.134v16.433c0,2.992,0.521,5.116,1.561,6.372
          c1.041,1.255,2.773,1.883,5.198,1.883c3.096,0,5.615-0.95,7.56-2.851c1.943-1.901,2.914-4.39,2.914-7.468V92.887H1119.422z"/>
              <path fill="#FFFFFF" d="M1122.174,111.978h5.624c0.24,1.806,1.074,3.198,2.502,4.179c1.428,0.981,3.354,1.471,5.779,1.471
          c2.786,0,4.953-0.378,6.501-1.135c1.548-0.757,2.321-1.807,2.321-3.147c0-0.98-0.361-1.724-1.083-2.231
          c-0.724-0.507-2.073-0.924-4.052-1.251l-6.319-1.109c-3.887-0.653-6.515-1.548-7.881-2.683c-1.367-1.135-2.052-2.829-2.052-5.082
          c0-2.958,1.079-5.207,3.237-6.746s5.318-2.309,9.48-2.309c4.18,0,7.374,0.796,9.584,2.386c2.21,1.591,3.313,3.883,3.313,6.875
          v0.207h-4.979c-0.173-1.737-0.93-3.027-2.271-3.87c-1.341-0.842-3.311-1.264-5.907-1.264c-2.373,0-4.184,0.327-5.431,0.98
          c-1.247,0.654-1.87,1.6-1.87,2.838c0,1.083,0.378,1.896,1.135,2.438c0.758,0.542,2.494,1.045,5.213,1.509l6.14,1.006
          c3.388,0.567,5.739,1.449,7.056,2.644c1.315,1.195,1.974,2.979,1.974,5.353c0,2.924-1.218,5.177-3.649,6.759
          c-2.436,1.582-5.921,2.373-10.461,2.373c-4.386,0-7.718-0.834-9.997-2.502C1123.804,117.997,1122.501,115.435,1122.174,111.978z"/>
              <path fill="#FFFFFF"
                    d="M1158.463,89.198h-5.107v-6.191h5.107V89.198z M1153.355,121.161V92.887h5.106v28.274H1153.355z"/>
              <path fill="#FFFFFF" d="M1186.008,102.252c-0.517-1.737-1.506-3.096-2.967-4.076c-1.462-0.98-3.225-1.47-5.289-1.47
          c-3.232,0-5.791,0.929-7.674,2.786c-1.884,1.857-2.825,4.377-2.825,7.559c0,3.113,0.929,5.611,2.786,7.494
          c1.857,1.884,4.309,2.825,7.354,2.825c2.32,0,4.276-0.615,5.867-1.845c1.592-1.23,2.56-2.876,2.902-4.94h5.393
          c-0.619,3.594-2.211,6.423-4.772,8.487c-2.563,2.063-5.762,3.096-9.597,3.096c-4.523,0-8.199-1.401-11.028-4.205
          c-2.829-2.803-4.243-6.44-4.243-10.912c0-4.488,1.419-8.13,4.257-10.925c2.837-2.794,6.543-4.192,11.118-4.192
          c3.697,0,6.812,0.929,9.339,2.786c2.528,1.857,4.093,4.369,4.695,7.533L1186.008,102.252L1186.008,102.252z"/>
      </g>
    </svg>
  )
};

export default CloudlandLogo;
