import Form from "./Form/Form";
import React from 'react'

const Contact = props => (
  <section id="contact">
    <div className="inner">
      <section>
        <Form />
      </section>
      <section className="split">
        <section>
          <div className="contact-method">
            <span className="icon alt fa-envelope" />
            <h3>Demos: </h3>
            <a href="mailto:demos@cloudlandmusic.com">
              demos@cloudlandmusic.com
            </a>
          </div>
        </section>
        <section>
          <div className="contact-method">
            <span className="icon alt fa-envelope" />
            <h3>Other inquiries:</h3>
            <a href="mailto:info@cloudlandmusic.com">info@cloudlandmusic.com</a>
          </div>
        </section>
        <section>
          <div className="contact-method">
            <span className="icon alt fa-commenting" />
            <h3>Direct message:</h3>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="http://m.me/cloudlandmusic"
            >
              message
            </a>
          </div>
        </section>
      </section>
    </div>
  </section>
);

export default Contact;
