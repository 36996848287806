import React, { Component } from "react";
import styled from "styled-components";
import Observer from "@researchgate/react-intersection-observer";
import { Center, CenterAbsolute, Title } from "./styledComponents";
import CloudSpinner from "./Loader/CloudSpinner";

const Container = styled.div`
  position: relative;
  padding: 5rem;
  @media (max-width: 700px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

class Spotify extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isIntersecting: false,
      isPlayerLoaded: false
    };

    this.handleIntersection = this.handleIntersection.bind(this);
  }

  handleIntersection(event) {
    if (event.isIntersecting) {
      this.setState({ isIntersecting: true });
      setTimeout(
        function() {
          this.setState({ isPlayerLoaded: true });
        }.bind(this),
        3500
      );
    }
  }

  render() {
    const observerOptions = {
      onChange: this.handleIntersection,
      rootMargin: "25%"
    };

    return (
      <Container>
        <div className="inner">
          <header className="major">
            <Title>Spotify</Title>
          </header>
          <Observer {...observerOptions}>
            {this.state.isIntersecting ? (
              <iframe
                src="https://open.spotify.com/embed/playlist/3d9kg4pX4T4OkOg0qobDKS"
                width={this.state.isPlayerLoaded ? "100%" : "0%"}
                height="500"
                frameBorder="0"
                allow="encrypted-media"
              />
            ) : (
              <div />
            )}
          </Observer>
          {!this.state.isPlayerLoaded && (
            <CenterAbsolute>
              <CloudSpinner />
            </CenterAbsolute>
          )}
        </div>
      </Container>
    );
  }
}

export default Spotify;
