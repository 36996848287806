import React from 'react'

const Menu = props => (
  <nav id="menu">
    <div className="inner">
      <ul className="links">
        <li>
          <a href="#banner">
            <span onClick={props.onToggleMenu}>Home</span>
          </a>
        </li>
        <li>
          <a href="#releases">
            <span onClick={props.onToggleMenu}>Releases</span>
          </a>
        </li>
        <li>
          <a href="#testimonials">
            <span onClick={props.onToggleMenu}>Testimonials</span>
          </a>
        </li>
        <li>
          <a href="#contact">
            <span onClick={props.onToggleMenu}>Contacts</span>
          </a>
        </li>
      </ul>
      {/*<section id="spotify">*/}
      {/*<Spotify/>*/}
      {/*</section>*/}
    </div>
    <a className="close" onClick={props.onToggleMenu} href="javascript:;">
      Close
    </a>
  </nav>
);

export default Menu;
