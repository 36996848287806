import React from 'react';
import PaginationDot from './PaginationDot';
import styled from "styled-components";

const Container = styled.div`
  bottom: 8px;
  right: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

class Pagination extends React.Component {
  render() {
    const { index, dots } = this.props;

    const children = [];

    for (let i = 0; i < dots; i += 1) {
      children.push(
        <PaginationDot key={i} index={i} active={i === index} />,
      );
    }

    return <Container>{children}</Container>;
  }
}

export default Pagination;