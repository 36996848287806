import CloudlandTitle from './Logos/CloudlandTitle'
import React from 'react'

const Banner = ({ coverUrl }) => (
  <section
    id="banner"
    className="major"
    style={{ backgroundImage: `url(${coverUrl})` }}
  >
    <div className="inner">
      <header className="major">
        <CloudlandTitle />
      </header>
      <div className="content">
        <p>
          Cloudland Music is an electronic dance music label based in Nashville,
          TN, USA. Founded by business partners Leonardo Alvarez and Paris Loyo,
          both of whom are electronic/dance music enthusiasts. They decided to
          create Cloudland Music in order to cover the best in trance & modern
          electronic music. Now the label is headed by Leonardo & Eric Palmieri
          with that same mission.
        </p>
      </div>
    </div>
  </section>
)

export default Banner
