import React, { Component } from "react";
import Observer from "@researchgate/react-intersection-observer";

class Release extends Component {
  constructor(props) {
    super(props);

    super(props);

    this.state = {
      isMouseHovering: false,
      isIntersecting: false
    };

    this.handleMouseHover = this.handleMouseHover.bind(this);
    this.handleIntersection = this.handleIntersection.bind(this);
  }

  handleMouseHover() {
    this.setState({ isMouseHovering: !this.state.isMouseHovering });
  }

  handleIntersection(event) {
    if (event.isIntersecting) {
      this.setState({ isIntersecting: event.isIntersecting });
    }
  }

  render() {
    let { coverUrl, artists, songTitle, smartLink, mixes } = this.props;
    let { isIntersecting } = this.state;

    const observerOptions = {
      onChange: this.handleIntersection,
      rootMargin: "-5%"
    };

    return (
      <Observer {...observerOptions}>
        <article
          style={{
            backgroundImage: isIntersecting ? `url('${coverUrl}')` : `url()`,
          }}
          onMouseOver={this.handleMouseHover}
          onMouseLeave={this.handleMouseHover}
        >
          <header
            className="major"
            style={{
              transition: "all 0.5s ease",
              transform: this.state.isMouseHovering ? "scale(1.2)" : "scale(1)",
              opacity: this.state.isMouseHovering ? 0 : 1
            }}
          >
            <h3>{artists}</h3>
            <p>
              {songTitle}
              {mixes ? " / " + mixes : ""}
            </p>
          </header>
          <a
            className="link primary"
            href={smartLink}
            target="_blank"
            rel="noopener noreferrer"
          />
        </article>
      </Observer>
    );
  }
}

export default Release;
