import SwipeableViews from 'react-swipeable-views';
import {autoPlay} from 'react-swipeable-views-utils';
import Pagination from './Pagination';
import Slide from "./Slide";
import {Component} from "react";
import React from 'react'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

class Carousel extends Component {
  state = {
    index: 0,
  };

  handleChangeIndex = index => {
    this.setState({index});
  };

  render() {
    const {index} = this.state;
    const {quotes} = this.props;

    return (
      <div className="inner" style={{height: '50vh'}}>
        <AutoPlaySwipeableViews
          index={index}
          onChangeIndex={this.handleChangeIndex}
        >
          {
            quotes.map((quote, index) => (
              <Slide
                key={index}
                name={quote.name}
                message={quote.message}
              />
            ))
          }
        </AutoPlaySwipeableViews>
        <Pagination
          dots={quotes.length}
          index={index}
        />
      </div>
    );
  }
}

export default Carousel;
