import React from 'react';
import styled from 'styled-components';

const Cloud = styled.div`
  background: #fff;
	
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	border-radius: 100px;

	height: 120px;
	position: relative;
	width: 350px;
	
	&:before, &:after{
	   background: #fff;
    content: '';
    position: absolute;
    z-indeX: -1;
	}
	
	&:after {
	  -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
  
    height: 100px;
    left: 50px;
    top: -50px;
    width: 100px;
	}
	
	&:before {
	  -webkit-border-radius: 200px;
    -moz-border-radius: 200px;
    border-radius: 200px;
  
    width: 180px;
    height: 180px;
    right: 50px;
    top: -90px;
	}
`;


export default Cloud;
