export default {
  bg: 'rgba(2, 57, 74, 1)',
  bgalt: 'rgba(29, 53, 87, 1)',
  fg: 'rgba(241, 250, 238, 1)',
  fgbold: 'rgba(241, 250, 238, 1)',
  fglight: 'rgba(244, 244, 255, 0.2)',
  border: 'rgba(212, 212, 255, 0.1)',
  borderbg: 'rgba(212, 212, 255, 0.035)',
  highlight: 'rgba(168, 218, 220, 1)',
  accent1: '#087E8B',
  accent2: 'rgba(69, 123, 157, 1)',
  accent3: '#08415C',
  accent4: 'rgba(2, 57, 74, 1)',
  accent5: 'rgba(29, 53, 87, 1)',
}