import { Link } from 'gatsby'
import React from 'react'
import CloudlandLogo from "./Logos/CloudlandLogo";
import colors from './styledComponents/colors';
import WithWindowScroll from "../HOC/WithWindowScroll";

const Header = (props) => {

  const getOpacityFromScrollPosition = () => {
    const {scrollDistanceFromTop} = props;

    if(scrollDistanceFromTop <= 70){
      return 1;
    } else if(scrollDistanceFromTop >= 70 && scrollDistanceFromTop <= 600){
      return 0;
    } else if(scrollDistanceFromTop > 600){
      return 1;
    }
  };

  return (
    <header
      id="header"
      className="alt"
      style={{
        position: props.scrollDistanceFromTop > 700 ? 'fixed' : 'absolute',
        transition: 'all 0.4 ease',
        opacity: getOpacityFromScrollPosition(),
        backgroundColor: props.scrollDistanceFromTop > 700 ? colors.bg : 'transparent',
        zIndex: '90'
      }}
    >

      <Link href="/">
        <a
          className="logo"
          style={{
            opacity: props.scrollDistanceFromTop > 700 ? 1 : 0,
            transition: 'all 0.8s ease'
          }}
        >
          <CloudlandLogo/>
        </a>
      </Link>

      <nav>
        <a className="menu-link" onClick={props.onToggleMenu} href="javascript:;"></a>
      </nav>
    </header>
  )
};

const HeaderWithScroll = (otherProps) => {
  return (
    <WithWindowScroll>
      {
        props => (
          <Header
            {...otherProps}
            {...props}
          />
        )
      }
    </WithWindowScroll>
  )
};

export default HeaderWithScroll
