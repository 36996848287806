import React, {Component} from 'react'

export default class WithWindowScroll extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientHeight: 0,
      scrollTop: 0
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, {passive: true})
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll() {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const clientHeight = document.body.offsetHeight;

    this.setState({
      scrollTop, clientHeight
    })
  }

  render() {
    return this.props.children({
      scrollDistanceFromTop: this.state.scrollTop
    })
  }
}