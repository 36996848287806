import React from 'react';
import Cloud from "./Cloud";
import {Title} from "../styledComponents";
import posed from "react-pose";

const CloudAnimated = posed.div({
  left: {x: -100},
  right: {x: 100}
});

const Clouds = (props) => {
  return (
    <>
      <CloudAnimated pose={props.isMoving ? 'left' : 'right'}>
        <Cloud style={{transform: 'scale(0.4)'}}/>
      </CloudAnimated>
      <br/>
      <CloudAnimated pose={props.isMoving ? 'right' : 'left'}>
        <Cloud style={{transform: 'scale(0.7)'}}/>
      </CloudAnimated>
      <br/>

      <Title>
        {props.message}
      </Title>

      <CloudAnimated pose={props.isVisible ? 'left' : 'right'}>
        <Cloud style={{transform: 'scale(0.2)'}}/>
      </CloudAnimated>
    </>
  );
};

export default Clouds;
