import styled from 'styled-components'
import {Title} from "../styledComponents";
import React from 'react'

const Paragraph = styled.p`
  text-align: center;
  font-style: italic;
`;

const QuoteMark = styled.span`
  font-size: 40px;
`;

const Slide = ({name, message}) => {
  return (
    <div className="inner">
      <header className="major">
        <Title>
          {name}
        </Title>
      </header>
      <Paragraph>
        <QuoteMark>"</QuoteMark>{message}<QuoteMark>"</QuoteMark>
      </Paragraph>
    </div>
  );
};

export default Slide;
