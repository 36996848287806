import React, { Component } from "react";
import styled from "styled-components";
import Clouds from "./Clouds";

const Container = styled.div`
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(2, 57, 74, 0.8);
  position: fixed;
`;

const InnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

class Loader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMoving: false
    };
  }

  componentDidMount() {
    this.intervalId = setInterval(() => {
      this.setState({ isMoving: !this.state.isMoving });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    return (
      <Container>
        <InnerContainer>
          <Clouds {...this.state} message={this.props.message} />
        </InnerContainer>
      </Container>
    );
  }
}

export default Loader;
