import React, { Component } from "react";
import Loader from "../Loader/Loader";

class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }

  handleChange(e) {
    const { value, name } = e.target;
    this.setState({ [name]: value });
  }

  async sendEmail(formData) {
    const response = await fetch(
      'https://us-central1-cloudlandmusic-3a1db.cloudfunctions.net/cloudlandmusicapi/send',
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(formData),
      }
    )
    return await response.json()
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.setState({
      isLoading: true,
      loadingMessage: "Sending email to the cloud..."
    });
    //Open loading modal maybe re-captcha
    const { isLoading, loadingMessage, ...formData } = this.state;

    try {
      // Send email logic
      await this.sendEmail(formData)

      setTimeout(() => {
        this.setState({ loadingMessage: "Email has reached the cloud!" });
      }, 2000);
    } catch (e) {
      this.setState({
        loadingMessage:
          "Sorry email could not reach the cloud =(, please try again"
      });
    } finally {
      this.setState({
        email: "",
        message: "",
        name: ""
      });
      setTimeout(() => {
        this.setState({ isLoading: false });
      }, 4000);
    }
  }

  render() {
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <div className="field half first">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              id="name"
              onChange={this.handleChange}
              value={this.state.name}
              required
            />
          </div>
          <div className="field half">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              name="email"
              id="email"
              onChange={this.handleChange}
              value={this.state.email}
              required
            />
          </div>
          <div className="field">
            <label htmlFor="message">Message</label>
            <textarea
              name="message"
              id="message"
              rows="6"
              onChange={this.handleChange}
              value={this.state.message}
              required
            />
          </div>
          <ul className="actions">
            <li>
              <input type="submit" value="Send Message" className="special" />
            </li>
          </ul>
        </form>
        {this.state.isLoading && <Loader message={this.state.loadingMessage} />}
      </>
    );
  }
}

export default Form;
