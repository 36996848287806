import React from "react";
import Release from "./Release";

const Releases = ({ releases }) => {
  return (
    <>
      {releases.map((release, index) => (
        <Release key={release.id} index={index} {...release} />
      ))}
    </>
  );
};

export default Releases;
